import React, { useState } from "react";
import { Alert, Breadcrumbs, Divider, Snackbar, Box } from "@mui/material";
import { ActiveLink, PageTitle, PathLink } from "../../helper/style";
import CompanyTable from "./elements/CompanyTable";
import CreateNewCompany from "./elements/CreateNewCompany";
import UpdateCompany from "./elements/UpdateCompany";
import CrudSitePlan from "./elements/CrudSitePlan";
import NotificationDialog from "./elements/NotificationDialog";
import { deleteCompany, restoreCompany } from "../../api/company";
import DeleteDialog from "./elements/DeleteCompanyDialog";
import CompanyFilter from "./elements/FilterCompany";

const Company = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [formOpen, setFormOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [reload, setReload] = useState(false);
  const [companyData, setCompanyData] = useState({
    company_name: "",
    latitude: "",
    longitude: "",
    first: "",
    subsequent: "",
    notification: {
      device_offline: false,
      plan_expiry: false,
      timer: false,
      switch: false,
      timer_set: false,
      physical: false,
    },
    allow_location: false,
    allow_ticketing: false
  });

  const [dialog, setDialog] = React.useState(false);
  const [newPlan, setNewPlan] = useState({
    plan_code: "",
    plan_name: "",
    users_allowed: 1,
    groups_allowed: 1,
    price: "",
    time_period: "",
  });
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    deleted: false,
    createdAt: "",
  });
  const [filterCount, setFilterCount] = useState(1);


  const [planUpdate, setPlanUpdate] = useState(false);
  const [planId, setPlanId] = useState();
  const [companyList, setCompanyList] = useState([]);
  const [notificationData, setNotificationData] = useState({
    title: "",
    body: "",
    companies: [],
  });
  const [notificationOpen, setNotificationOpen] = useState(false);
  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setNotificationData({
      title: "",
      body: "",
      companies: [],
    });
  };
  const [companyId, setCompanyId] = useState("");
  const [restore, setRestore] = useState(false);

  const handleClose = () => {
    setAlertOpen(false);
  };
  const handleFormClose = () => {
    setFormOpen(false);
    setReload(!reload);
    setCompanyData({
      company_name: "",
      latitude: "",
      longitude: "",
    });
    setUpdateOpen(false);
    setCompanyId("");
    setDialog(false);
    setFilterOpen(false);
    let count = 0;
    if (filterData.deleted !== "") {
      count = count + 1;
    }
    if (filterData.createdAt !== "") {
      count = count + 1;
    }
    setFilterCount(count);
    setRestore(false);
  };
  const handleDeleteConfirm = () => {
    setDeleteLoading(true);
    deleteCompany(
      setSeverity,
      setMessage,
      setAlertOpen,
      setDeleteLoading,
      setDeleteOpen,
      companyId,
      handleFormClose
    );
  };

  const handleRestoreConfirm = () => {
    setDeleteLoading(true);
    restoreCompany(
      setSeverity,
      setMessage,
      setAlertOpen,
      setDeleteLoading,
      setDeleteOpen,
      companyId,
      handleFormClose
    );
  };

  const handleDeleteCancel = () => {
    setDeleteOpen(false);
  };
  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "80%" }}>
          <PageTitle>Site</PageTitle>
          <Breadcrumbs aria-label="breadcrumb">
            <PathLink underline="hover" href="/dashboard">
              Dashboard
            </PathLink>
            <ActiveLink>Site</ActiveLink>
          </Breadcrumbs>
        </Box>
      </Box>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />

      <CompanyTable
        setMessage={setMessage}
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        reload={reload}
        setFormOpen={setFormOpen}
        setUpdateOpen={setUpdateOpen}
        companyData={companyData}
        setCompanyData={setCompanyData}
        setCompanyId={setCompanyId}
        setDialog={setDialog}
        setPlanUpdate={setPlanUpdate}
        setPlanId={setPlanId}
        setNewPlan={setNewPlan}
        companyList={companyList}
        setCompanyList={setCompanyList}
        setDeleteOpen={setDeleteOpen}
        filterCount={filterCount}
        setFilterOpen={setFilterOpen}
        filterData={filterData}
        setNotificationOpen={setNotificationOpen}
        setRestore={setRestore}
      />

      {/* create company */}
      <CreateNewCompany
        formOpen={formOpen}
        handleFormClose={handleFormClose}
        setMessage={setMessage}
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        companyData={companyData}
        setCompanyData={setCompanyData}
      />

      {/* update company */}
      <UpdateCompany
        updateOpen={updateOpen}
        handleFormClose={handleFormClose}
        setMessage={setMessage}
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        companyData={companyData}
        setCompanyData={setCompanyData}
        companyId={companyId}
      />

      <CrudSitePlan
        dialog={dialog}
        handleClose={handleFormClose}
        newPlan={newPlan}
        setNewPlan={setNewPlan}
        setMessage={setMessage}
        setAlertOpen={setAlertOpen}
        setSeverity={setSeverity}
        companyId={companyId}
        planUpdate={planUpdate}
        planId={planId}
      />

      {notificationOpen && (
        <NotificationDialog
          setSeverity={setSeverity}
          setMessage={setMessage}
          setAlertOpen={setAlertOpen}
          notificationOpen={notificationOpen}
          handleNotificationClose={handleNotificationClose}
          notificationData={notificationData}
          setNotificationData={setNotificationData}
        />
      )}
      {deleteOpen && (
        <DeleteDialog
          setSeverity={setSeverity}
          setMessage={setMessage}
          setAlertOpen={setAlertOpen}
          deleteOpen={deleteOpen}
          deleteLoading={deleteLoading}
          handleDeleteConfirm={handleDeleteConfirm}
          handleDeleteCancel={handleDeleteCancel}
          handleFormClose={handleFormClose}
          restore={restore}
          handleRestoreConfirm={handleRestoreConfirm}
        />
      )}
      {filterOpen && (
        <CompanyFilter
          filterOpen={filterOpen}
          handleFilterClose={handleFormClose}
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterCount={setFilterCount}
        />
      )}
    </>
  );
};

export default Company;
