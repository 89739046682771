import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
} from "@mui/material";
import { updateCompany } from "../../../api/company";

const UpdateCompany = ({
  updateOpen,
  handleFormClose,
  setSeverity,
  setMessage,
  setAlertOpen,
  companyData,
  setCompanyData,
  companyId,
}) => {
  const handleSubmit = () => {
    updateCompany(
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose,
      companyId,
      companyData
    );
  };
  return (
    <React.Fragment>
      <Dialog open={updateOpen} onClose={handleFormClose}>
        <DialogTitle>Update Site</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="siteName"
            label="Site Name"
            value={companyData.company_name}
            name="siteName"
            onChange={(e) => {
              setCompanyData({ ...companyData, company_name: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            id="First Notificaton"
            label="First Notificaton (Mins)"
            value={companyData.first ? companyData.first : ""}

            name="First Notificaton"
            placeholder="In Mins."
            onChange={(e) => {
              setCompanyData({
                ...companyData,
                first: e.target.value,
              });
            }}

          />
          <TextField
            margin="normal"
            fullWidth
            id="Subsequent Notification"
            label="Subsequent Notification (Hrs)"
            value={companyData.subsequent ? companyData.subsequent : ""}
            name="Subsequent Notification"
            placeholder="In hrs."
            onChange={(e) => {
              setCompanyData({
                ...companyData,
                subsequent: e.target.value,
              });
            }}
          />

          <TextField
            margin="normal"
            fullWidth
            id="First Notificaton"
            label="First Notificaton (Mins)"
            value={companyData.first ? companyData.first : ""}

            name="First Notificaton"
            placeholder="In Mins."
            onChange={(e) => {
              setCompanyData({
                ...companyData,
                first: e.target.value,
              });
            }}

          />
          <TextField
            margin="normal"
            fullWidth
            id="Subsequent Notification"
            label="Subsequent Notification (Hrs)"
            value={companyData.subsequent ? companyData.subsequent : ""}
            name="Subsequent Notification"
            placeholder="In hrs."
            onChange={(e) => {
              setCompanyData({
                ...companyData,
                subsequent: e.target.value,
              });
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="Latitude"
            label="Latitude"
            value={companyData.latitude}
            name="Latitude"
            onChange={(e) => {
              setCompanyData({ ...companyData, latitude: e.target.value });
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="Longitude"
            label="Longitude"
            value={companyData.longitude}
            name="Longitude"
            onChange={(e) => {
              setCompanyData({ ...companyData, longitude: e.target.value });
            }}
          />
          <FormControlLabel
            label="Has metering"
            control={
              <Checkbox
                inputProps={{ "aria-label": "Has metering" }}
                checked={companyData.allow_metering}
                sx={{ alignSelf: "center" }}
                onChange={(e) => {
                  setCompanyData({
                    ...companyData,
                    allow_metering: e.target.checked,
                  });
                }}
              />
            }
            style={{ display: "flex" }}
          />
          <FormControlLabel
            label="Allow location access"
            control={
              <Checkbox
                inputProps={{ "aria-label": "Allow location access" }}
                checked={companyData.allow_location}
                sx={{ alignSelf: "center" }}
                onChange={(e) => {
                  setCompanyData({
                    ...companyData,
                    allow_location: e.target.checked,
                  });
                }}
              />
            }
            style={{ display: "flex" }}
          />

          <FormControlLabel
            label="Allow Ticketing"
            control={
              <Checkbox
                inputProps={{ "aria-label": "Allow Ticketing" }}
                checked={companyData.allow_ticketing}
                sx={{ alignSelf: "center" }}
                onChange={(e) => {
                  setCompanyData({
                    ...companyData,
                    allow_ticketing: e.target.checked,
                  });
                }}
              />
            }
            style={{ display: "flex" }}
          />
          <Typography variant="h6" gutterBottom>
            Notifications:
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <FormControlLabel
                label="Device Offline"
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "Device Offline" }}
                    checked={companyData.notification?.device_offline}
                    onChange={(e) => {
                      setCompanyData({
                        ...companyData,
                        notification: {
                          ...companyData.notification,
                          device_offline: e.target.checked
                        }
                      });
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="Plan Expiry"
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "Plan Expiry" }}
                    checked={companyData.notification?.plan_expiry}
                    onChange={(e) => {
                      setCompanyData({
                        ...companyData,
                        notification: {
                          ...companyData.notification,
                          plan_expiry: e.target.checked
                        }
                      });
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="Load On/Off through Switch"
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "Load On/Off through Switch" }}
                    checked={companyData.notification?.switch}
                    onChange={(e) => {
                      setCompanyData({
                        ...companyData,
                        notification: {
                          ...companyData.notification,
                          switch: e.target.checked
                        }
                      });
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="Load On/Off through timer"
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "Load On/Off through timer" }}
                    checked={companyData.notification?.timer}
                    onChange={(e) => {
                      setCompanyData({
                        ...companyData,
                        notification: {
                          ...companyData.notification,
                          timer: e.target.checked
                        }
                      });
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="Physically Load On/Off"
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "Physically Load On/Off" }}
                    checked={companyData.notification?.physical}
                    onChange={(e) => {
                      setCompanyData({
                        ...companyData,
                        notification: {
                          ...companyData.notification,
                          physical: e.target.checked
                        }
                      });
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="Timer Set"
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "Timer Set" }}
                    checked={companyData.notification?.timer_set}
                    onChange={(e) => {
                      setCompanyData({
                        ...companyData,
                        notification: {
                          ...companyData.notification,
                          timer_set: e.target.checked
                        }
                      });
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ padding: "16px 28px" }}>
          <Button
            type="submit"
            variant="outlined"
            onClick={handleSubmit}
            color="secondary"
          >
            Update Site
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default UpdateCompany;
